import CookieService from '@/services/cookie';

export default {
  data() {
    return {
      viewportHeight: window.innerHeight,
    };
  },
  computed: {
    viewportStyle() {
      return {
        height: `${
          this.hasNavPinned
            ? this.viewportHeight - (this.hasNavPinned ? 45 : 0)
            : this.viewportHeight
        }px`,
      };
    },
    hasNavPinned() {
      return CookieService.getNavPin();
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.viewportHeight = window.innerHeight;
    },
  },
};
