<template>
  <div class="home-container" :style="viewportStyle">
    <div
      v-if="accessedUser"
      class="user-access-bar d-flex justify-center align-center"
    >
      Accessed account:
      {{ accessedUser.email }}
      <v-divider vertical class="mx-5" />
      <v-btn icon @click="disableAccountAccess">
        <v-icon> mdi-close</v-icon>
      </v-btn>
    </div>
    <transition name="fade" mode="out-in">
      <router-view
        v-if="authenticatedUser && selectedLicense"
        class="content-container"
        :style="viewportStyle"
      />
    </transition>
    <app-bar class="application-bar" />
    <task-sidebar class="sidebar" :style="viewportStyle" />
    <whats-new-sidebar class="sidebar" :style="viewportStyle" />
  </div>
</template>
<script>
import AppBar from '@/components/AppBar';
import TaskSidebar from '@/components/Tasks/TaskSidebar';
import CookieService from '@/services/cookie';
import { mapGetters } from 'vuex';
import { ACC_TOKEN } from '@/services/forge/autodesk-construction-cloud';
import LocalStorageService from '../services/local-storage';
import WhatsNewSidebar from '@/components/News/WhatsNewSidebar.vue';
import ViewportStyleMixin from '@/Mixins/ViewportStyleMixin';
import { fetchProjectBySlugV2 } from '@/services/api/v2/projects.v2.api';
import { getProject } from '@/services/api/projects.api';

export default {
  name: 'Home',
  components: { WhatsNewSidebar, TaskSidebar, AppBar },
  mixins: [ViewportStyleMixin],
  computed: {
    ...mapGetters([
      'authenticatedUser',
      'authenticatedStatus',
      'accessedUser',
      'selectedLicense',
    ]),
  },
  watch: {
    authenticatedUser: {
      deep: true,
      handler() {
        if (this.$cookies.get(ACC_TOKEN)) {
          this.$store.commit(
            'acc_access_token_success',
            this.$cookies.get(ACC_TOKEN)
          );
        }
      },
    },
    '$route.params.slug': {
      immediate: true,
      deep: true,
      async handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          const projectSlug = await fetchProjectBySlugV2(newValue);
          const project = await getProject(projectSlug.id);
          this.$store.commit(
            'set_license',
            this.authenticatedUser.licenses.find(
              (item) => item.id === project.license
            )
          );
          this.$store.commit('set_project', project);
        }
      },
    },
  },
  async mounted() {
    await this.$store.dispatch('fetchAuthenticatedUser');
    let expirationDays = this.authenticatedUser.password_expires_in;
    if (expirationDays <= 0) {
      await this.$router.push({ name: 'Settings', query: { status: 'reset' } });
    }
    if (!this.$route.params.slug && !this.selectedLicense) {
      if (this.authenticatedUser.licenses.length === 1) {
        this.$store.commit('set_license', this.authenticatedUser.licenses[0]);
      } else {
        const licenseId = window.sessionStorage.getItem('license');
        if (licenseId) {
          this.$store.commit(
            'set_license',
            this.authenticatedUser.licenses.find((x) => x.id === licenseId)
          );
        } else {
          await this.$router.push({
            name: 'Licenses',
            query: { redirect: this.$route.query.redirect },
          });
        }
      }
    }
    if (expirationDays < 7 && expirationDays >= 1) {
      this.$store.commit('showNotification', {
        content: `Your password expires in ${expirationDays} day(s), please reset your password`,
        color: 'warning',
      });
    }
  },
  methods: {
    disableAccountAccess() {
      LocalStorageService.setToken(LocalStorageService.getParentToken());
      LocalStorageService.removeParentToken();
      this.$store.commit('disable_account_access');
      this.$router.push('/dashboard');
    },
  },
};
</script>

<style scoped lang="scss">
.home-container {
  background-image: linear-gradient(120deg, #eef1f5 20%, #d6d8dc 80%);
  background-blend-mode: lighten;
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  flex-direction: column;

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  .user-access-bar {
    height: 50px;
    background: white;
    border-bottom: solid 4px var(--v-primary-base);
  }

  &.nav-pinned {
    .application-bar {
      position: relative;
    }
  }

  .content-container {
    flex: 1;
  }

  .application-bar {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 100;
  }

  .sidebar {
    position: fixed;
    top: 0;
    right: 0;
    width: 450px;
    z-index: 20;
  }

  .news-sidebar {
  }
}
</style>
