export default {
  data() {
    return {
      isPortrait: false,
    };
  },
  created() {
    this.checkOrientation();
    window.addEventListener('resize', this.checkOrientation);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkOrientation);
  },
  methods: {
    checkOrientation() {
      this.isPortrait = window.matchMedia('(orientation: portrait)').matches;
    },
  },
};
