<template>
  <div
    v-if="selectedLicense"
    class="app-bar-container"
    :class="{ 'nav-pinned': hasNavPinned }"
  >
    <div class="ant-glass-background app-bar radius-0 pos-rel d-flex">
      <div class="flex-1 d-flex align-center justify-start">
        <div class="ant-logo">ANT</div>
        <v-divider vertical inset class="mx-2" />
        <div
          style="color: grey; font-size: 12px"
          class="c-pointer"
          @click="changeLicense()"
        >
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on"> {{ selectedLicense.name }} </span>
            </template>
            <span>{{ $t('system.appBar.changeEnv') }}</span>
          </v-tooltip>
        </div>
      </div>
      <div class="flex-1 d-flex align-center">
        <div class="flex-1 d-flex justify-start">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                icon
                to="/dashboard"
                @click="$store.commit('close_tasks_sidebar')"
                v-on="on"
              >
                <v-icon>mdi-view-dashboard</v-icon>
              </v-btn>
            </template>
            <span>Projects</span>
          </v-tooltip>
        </div>
        <transition name="fade-scale-rotate" mode="out-in">
          <div v-if="project" class="pos-rel d-flex justify-center">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  icon
                  :color="projectAppsMenu ? 'primary' : ''"
                  @click="appsMenuClick"
                  v-on="on"
                >
                  <v-icon>mdi-apps</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('system.appBar.ProjectAppsMenu') }}</span>
            </v-tooltip>
          </div>
        </transition>

        <div class="d-flex flex-1 justify-end">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn v-bind="attrs" icon @click="toggleTaskSidebar()" v-on="on">
                <v-icon :color="sidebarTasksDisplayed ? 'primary' : ''" dense
                  >mdi-clipboard-multiple
                </v-icon>
              </v-btn>
            </template>
            <span>Today's tasks</span>
          </v-tooltip>
          <v-divider vertical class="mx-1" />
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                icon
                @click="$router.push({ name: 'tasks' })"
                v-on="on"
              >
                <v-icon dense>mdi-calendar-month</v-icon>
              </v-btn>
            </template>
            <span>Tasks Overview</span>
          </v-tooltip>
        </div>
      </div>

      <div class="d-flex flex-1 justify-end align-center" style="color: grey">
        <div
          v-if="authenticatedUser"
          class="d-flex align-center"
          style="font-size: 12px; cursor: pointer"
          @click="settingsMenuClick"
        >
          <div class="mr-2">
            {{ authenticatedUser.firstname }} {{ authenticatedUser.lastname }}
          </div>
          <v-avatar color="primary" size="30" class="mr-2">
            <v-img
              v-if="authenticatedUser.photo"
              :src="`data:${authenticatedUser.photo.mimetype};base64, ${authenticatedUser.photo.file}`"
            />
            <span
              v-else
              class="white--text text-h6"
              style="font-size: 12px !important"
            >
              {{
                authenticatedUser.firstname.charAt(0) +
                authenticatedUser.lastname.charAt(0)
              }}
            </span>
          </v-avatar>
        </div>
        <notifications
          ref="notifications"
          class="mr-2"
          @on-shown="
            projectAppsMenu = false;
            settingsMenu = false;
          "
        />
      </div>
    </div>
    <div style="height: 0">
      <project-apps
        style="position: absolute; left: calc(50% - 275px)"
        :menu-displayed="projectAppsMenu"
        @closeProjectMenu="projectAppsMenu = false"
      />
      <div class="flex-1">
        <div class="d-flex flex-row-reverse">
          <div
            class="settings-container"
            :class="{ 'active-appbar-menu': settingsMenu }"
            @mouseleave="settingsMenu = false"
          >
            <div class="settings-content">
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="toggleNavBarPin">
                    <v-icon>
                      {{ hasNavPinned ? 'mdi-pin-off' : 'mdi-pin' }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{
                    hasNavPinned
                      ? $t('system.appBar.unpin')
                      : $t('system.appBar.pin')
                  }}
                </span>
              </v-tooltip>
              <v-tooltip v-if="isManyLicenses" top>
                <template #activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" to="/licenses" v-on="on">
                    <v-icon>mdi-account-convert</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('system.appBar.changeEnv') }}</span>
              </v-tooltip>

              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn icon to="/settings" v-bind="attrs" v-on="on">
                    <v-icon>mdi-account-edit</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('system.appBar.profSett') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-if="isUserAdmin"
                    icon
                    to="/admin"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-account-supervisor-circle</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('system.appBar.accAdmin') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    to="/workflow-management"
                    v-on="on"
                  >
                    <v-icon>mdi-source-repository-multiple</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('system.appBar.WF') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" to="/downloads" v-on="on">
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </template>
                <span>Downloads</span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="openCollaborAllSupport()"
                  >
                    <v-icon>mdi-help-rhombus</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('system.appBar.Support') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" to="/build" v-on="on">
                    <v-icon>mdi-information-variant</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('system.appBar.buildInfo') }}</span>
              </v-tooltip>
              <!--              <v-tooltip top>-->
              <!--                <template #activator="{ on, attrs }">-->
              <!--                  <v-btn icon v-bind="attrs" v-on="on" to="/knowledge-base">-->
              <!--                    <v-icon>mdi-library</v-icon>-->
              <!--                  </v-btn>-->
              <!--                </template>-->
              <!--                <span>{{ $t('system.appBar.knowledgeBase') }}</span>-->
              <!--              </v-tooltip>-->

              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    href="https://docs.antcde.io/"
                    target="_blank"
                    v-on="on"
                  >
                    <v-icon>mdi-file-document-multiple</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('system.appBar.documentation') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <div class="pos-rel">
                    <v-btn
                      icon
                      v-bind="attrs"
                      :color="newsSidebarShown ? 'primary' : ''"
                      v-on="on"
                      @click="toggleNewsSidebar()"
                    >
                      <v-icon>mdi-star-box</v-icon>
                    </v-btn>
                    <div
                      v-if="newsSidebarHasNewsUpdates"
                      class="news-unread-indicator"
                    />
                  </div>
                </template>
                <span>{{ $t('system.appBar.whatsNew') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="logout()">
                    <v-icon>mdi-power</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('system.appBar.logOut') }}</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocalStorageService from '../services/local-storage';
import ProjectApps from './ProjectApps';
import Notifications from '@/components/Notifications/Notifications';
import { mapGetters } from 'vuex';
import CookieService, { ANT_NAV_PIN } from '@/services/cookie';
import appConfig from '@/appConfig';

export default {
  name: 'AppBar',
  components: { ProjectApps, Notifications },
  data: () => ({
    settingsMenu: false,
    projectAppsMenu: false,
  }),
  computed: {
    ...mapGetters([
      'authenticatedUser',
      'selectedLicense',
      'sidebarTasksDisplayed',
      'isUserAdmin',
      'project',
      'newsSidebarShown',
      'newsSidebarHasNewsUpdates',
      'news',
    ]),
    hasNavPinned() {
      return CookieService.getNavPin();
    },
    isManyLicenses() {
      return this.authenticatedUser?.licenses?.length > 1;
    },
  },
  mounted() {
    if (!CookieService.hasCookie(ANT_NAV_PIN)) {
      CookieService.setNavPin(1, -1);
    }
    this.$store.dispatch('fetchNewsUpdates');
  },
  methods: {
    toggleTaskSidebar() {
      this.$store.commit('toggle_tasks_sidebar');
    },
    toggleNewsSidebar() {
      this.$store.commit('toggle_news_sidebar');
    },
    logout() {
      LocalStorageService.removeToken();
      LocalStorageService.removeMaintenanceToken();
      this.$router.push({ name: 'Login' });
    },
    toggleNavBarPin() {
      CookieService.setNavPin(!CookieService.getNavPin(), -1);
    },
    openCollaborAllSupport() {
      window.open(
        `${
          appConfig.VUE_APP_COLLABORALL_PORTAL_URL
        }login?token=${LocalStorageService.getToken()}&provider=${
          appConfig.VUE_APP_COLLABORALL_PORTAL_PROVIDER
        }`,
        '_blank'
      );
    },
    changeLicense() {
      this.$router.push('/licenses');
    },
    appsMenuClick() {
      this.projectAppsMenu = !this.projectAppsMenu;
      this.settingsMenu = false;
      this.$refs['notifications'].isShown = false;
    },
    settingsMenuClick() {
      this.settingsMenu = !this.settingsMenu;
      this.projectAppsMenu = false;
      this.$refs['notifications'].isShown = false;
    },
  },
};
</script>

<style scoped lang="scss">
.app-bar-container {
  display: flex;
  flex-direction: column;
  transform: translateY(45px);
  transition: 200ms ease-in-out;
  padding-top: 20px;

  &:hover {
    transform: translateY(0);
  }

  &.nav-pinned {
    padding-top: 0 !important;
    transform: translateY(0) !important;
    z-index: 20 !important;
    border-top: solid 1px lightgray;
  }

  .app-bar {
    height: 45px;
    z-index: 10;

    .ant-logo {
      margin-left: 20px;
      font-size: 26px;
      font-weight: bold;
      color: var(--v-primary-base);
      letter-spacing: 2px;
    }
  }

  .settings-container {
    z-index: 9;
    opacity: 0;
    transform: translateY(0) scale(0);
    transition: all 400ms ease;
    position: absolute;
    right: 10px;

    &.active-appbar-menu {
      opacity: 1;
      transform: translateY(calc(-100% - 65px)) scale(1);
      box-shadow: rgba(17, 12, 46, 0.15) 0 48px 100px 0;
    }

    .settings-content {
      padding: 5px 8px;
      display: flex;
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 5px;
      backdrop-filter: blur(5px);

      .news-unread-indicator {
        background-color: var(--v-primary-base);
        position: absolute;
        top: 6px;
        right: 6px;
        border-radius: 100%;
        border: 1px solid rgba(255, 255, 255, 0.75);
        height: 9px;
        width: 9px;
      }
    }
  }
}

.v-icon {
  font-size: 20px !important;
}
</style>
