export const defaultRoles = [
  {
    name: 'Verkoop Buiten',
  },
  {
    name: 'Verkoop Binnen',
  },
  {
    name: 'Calculator',
  },
  {
    name: 'Project Manager',
  },
  {
    name: 'Project Engineer',
  },
  {
    name: 'Projectleider',
  },
  {
    name: 'Werkvoorbereider',
  },
  {
    name: 'Tekenaar',
  },
  {
    name: 'Uitvoerder',
  },
  {
    name: 'Voorman',
  },
  {
    name: 'Halleider',
  },
  {
    name: 'Interne Kwaliteitsborger',
  },
  {
    name: 'Externe Kwaliteitsborger',
  },
];

export const defaultModules = [
  {
    name: 'Project Gantt',
  },
  {
    name: 'Manual Import',
  },
  {
    name: 'Project assembly planning',
  },
  {
    name: 'Bill of Material',
  },
  {
    name: 'Assembly Preparations',
  },
  {
    name: 'Module Assembly',
  },
  {
    name: 'Quality Control',
  },
  {
    name: 'Risico Analyse',
  },
  {
    name: 'Keuringsplan',
  },
  {
    name: 'Phase Transfer',
  },
  {
    name: 'Phase Transfer',
  },
  {
    name: 'DHME model import',
  },
];

export const rolesToModulesMapping = [
  {
    role: 'Verkoop Buiten',
    modules: ['Quality Control'],
    tables: ['CFFA_DHME_WKB_ANALYSIS', 'CFFA_DHME_WKB_PROJECT_RISKS_MEASURES'],
  },
  {
    role: 'Verkoop Binnen',
    modules: ['Quality Control'],
    tables: ['CFFA_DHME_WKB_ANALYSIS', 'CFFA_DHME_WKB_PROJECT_RISKS_MEASURES'],
  },
  {
    role: 'Calculator',
    modules: ['Bill of Material', 'Quality Control'],
    tables: [
      'CFFA_DHME_ELEMENTS',
      'CFFA_DHME_MODEL_MAPPING',
      'CFFA_DHME_MODULES',
      'CFFA_DHME_NON_MODULES',
      'CFFA_DHME_OBJECTS',
      'CFFA_DHME_RESOURCEGROUP_ELEMENTS',
      'CFFA_DHME_RESOURCEGROUP_OBJECTS',
      'CFFA_DHME_RESOURCEGROUPS',
      'CFFA_FORGE_CLIENT',
      'CFFA_FORGE_MODELS',
      'CFFA_DHME_WKB_ANALYSIS',
      'CFFA_DHME_WKB_PROJECT_RISKS_MEASURES',
    ],
  },
  {
    role: 'Project Engineer',
    modules: [
      'Project Gantt',
      'Risico Analyse',
      'Keuringsplan',
      'Phase Transfer',
      'Quality Control',
    ],
    tables: [
      'CFFA_DHME_MODULES',
      'CFFA_DHME_WKB_ANALYSIS',
      'CFFA_DHME_WKB_CHAPTER_STATUSES',
      'CFFA_DHME_WKB_CHAPTER_SUBSTANTIATION_DOCS',
      'CFFA_DHME_WKB_CHAPTER_SUBSTANTIATIONS',
      'CFFA_DHME_WKB_PROJECT_RISKS_MEASURES',
    ],
  },
  {
    role: 'Project Manager',
    modules: [
      'Project Gantt',
      'Risico Analyse',
      'Keuringsplan',
      'Phase Transfer',
      'Quality Control',
    ],
    tables: [
      'CFFA_DHME_MODULES',
      'CFFA_DHME_WKB_ANALYSIS',
      'CFFA_DHME_WKB_CHAPTER_STATUSES',
      'CFFA_DHME_WKB_CHAPTER_SUBSTANTIATION_DOCS',
      'CFFA_DHME_WKB_CHAPTER_SUBSTANTIATIONS',
      'CFFA_DHME_WKB_PROJECT_RISKS_MEASURES',
    ],
  },
  {
    role: 'Projectleider',
    modules: [
      'Project Gantt',
      'Quality Control',
      'Phase Transfer',
      'DHME model import',
    ],
    tables: [
      'CFFA_DHME_WKB_ANALYSIS',
      'CFFA_DHME_WKB_PROJECT_RISKS_MEASURES',
      'CFFA_FORGE_CLIENT',
      'CFFA_FORGE_MODELS',
      'CFFA_DHME_OBJECTS',
      'CFFA_DHME_ELEMENTS',
      'CFFA_DHME_MODULES',
      'CFFA_DHME_NON_MODULES',
      'CFFA_DHME_MODEL_MAPPING',
    ],
  },
  {
    role: 'Werkvoorbereider',
    modules: [
      'DHME model import',
      'Project Gantt',
      'Manual Import',
      'Project assembly planning',
      'Bill of Material',
      'Assembly Preparations',
      'Quality Control',
      'Phase Transfer',
      'DHME model import',
    ],
    tables: [
      'CFFA_DHME_ELEMENTS',
      'CFFA_DHME_ELEMENT_CONTENTS',
      'CFFA_DHME_MANUAL_IMPORT_GENERATIONS',
      'CFFA_DHME_MODULE_CONTENTS',
      'CFFA_DHME_MODULE',
      'CFFA_DHME_NON_MODULES',
      'CFFA_DHME_OBJECTS',
      'CFFA_DHME_OBJECTS_REGISTER',
      'CFFA_DHME_MODULES',
      'CFFA_FORGE_MODELS',
      'CFFA_DHME_MODEL_MAPPING',
      'CFFA_DHME_RESOURCEGROUP_ELEMENTS',
      'CFFA_DHME_RESOURCEGROUP_OBJECTS',
      'CFFA_DHME_RESOURCEGROUPS',
      'CFFA_FORGE_CLIENT',
      'CFFA_DHME_ASSEMBLY_PREPARATIONS_STATUS',
      'CFFA_DHME_WKB_ANALYSIS',
      'CFFA_DHME_WKB_PROJECT_RISKS_MEASURES',
    ],
  },
  {
    role: 'Tekenaar',
    modules: ['DHME model import', 'Quality Control'],
    tables: [
      'CFFA_FORGE_CLIENT',
      'CFFA_FORGE_MODELS',
      'CFFA_DHME_OBJECTS',
      'CFFA_DHME_ELEMENTS',
      'CFFA_DHME_MODULES',
      'CFFA_DHME_NON_MODULES',
      'CFFA_DHME_MODEL_MAPPING',
      'CFFA_DHME_WKB_ANALYSIS',
      'CFFA_DHME_WKB_PROJECT_RISKS_MEASURES',
    ],
  },
  {
    role: 'Uitvoerder',
    modules: ['Quality Control', 'Phase Transfer'],
    tables: ['CFFA_DHME_WKB_ANALYSIS', 'CFFA_DHME_WKB_PROJECT_RISKS_MEASURES'],
  },
  {
    role: 'Voorman',
    modules: ['Quality Control'],
    tables: ['CFFA_DHME_WKB_ANALYSIS', 'CFFA_DHME_WKB_PROJECT_RISKS_MEASURES'],
  },
  {
    role: 'Halleider',
    modules: ['Quality Control', 'Phase Transfer'],
    tables: ['CFFA_DHME_WKB_ANALYSIS', 'CFFA_DHME_WKB_PROJECT_RISKS_MEASURES'],
  },
  {
    role: 'Interne Kwaliteitsborger',
    modules: ['Quality Control', 'Keuringsplan', 'Risico Analyse'],
    tables: [
      'CFFA_DHME_WKB_ANALYSIS',
      'CFFA_DHME_WKB_PROJECT_RISKS_MEASURES',
      'CFFA_DHME_MODULES',
      'CFFA_DHME_WKB_CHAPTER_STATUSES',
      'CFFA_DHME_WKB_CHAPTER_SUBSTANTIATION_DOCS',
      'CFFA_DHME_WKB_CHAPTER_SUBSTANTIATIONS',
    ],
  },
  {
    role: 'Externe Kwaliteitsborger',
    modules: ['Quality Control', 'Keuringsplan', 'Risico Analyse'],
    tables: [
      'CFFA_DHME_WKB_ANALYSIS',
      'CFFA_DHME_WKB_PROJECT_RISKS_MEASURES',
      'CFFA_DHME_MODULES',
      'CFFA_DHME_WKB_CHAPTER_STATUSES',
      'CFFA_DHME_WKB_CHAPTER_SUBSTANTIATION_DOCS',
      'CFFA_DHME_WKB_CHAPTER_SUBSTANTIATIONS',
    ],
  },
];
