import {
  importTasksV2,
  queryTasksV2,
  removeAppendixV2,
  uploadAppendixV2,
} from '@/services/api/v2/tasks.v2.api';
import { queryTablesV2 } from '@/services/api/v2/tables.v2.api';
import {
  createRecordV2,
  downloadDocumentV2,
} from '@/services/api/v2/records.v2.api';

const state = {
  assemblyTasks: [],
  focusedTask: null,
  focusedHall: null,
  assemblyHalls: [],
  assemblyConfigurations: [],
  assemblyLocations: [],
  calendar: [],
  requestCounter: 0,
  projectModules: [],
  projectAssemblyTasks: [],
  projectModuleTableId: null,
  importCounter: 0,
};
const mutations = {
  make_request(state) {
    state.requestCounter++;
  },
  request_success(state) {
    state.requestCounter--;
  },
  assembly_tasks_success(state, tasks) {
    state.assemblyTasks = tasks;
    state.requestCounter--;
  },
  update_assembly_tasks(state, tasks) {
    tasks.forEach((t1) => {
      Object.assign(
        state.assemblyTasks.find((t2) => t2.id === t1.id),
        t1
      );
    });

    state.requestCounter--;
  },
  focus_on_hall(state, hall) {
    state.focusedHall = hall;
  },
  focus_on_task(state, task) {
    state.focusedTask = task;
  },
  assembly_halls_success(
    state,
    { calendar, halls, configurations, locations }
  ) {
    state.calendar = calendar;
    state.assemblyHalls = halls;
    state.assemblyConfigurations = configurations;
    state.assemblyLocations = locations;
    state.requestCounter--;
  },
  modules_success(state, { tableId, modules }) {
    state.projectModuleTableId = tableId;
    state.projectModules = modules;
    state.requestCounter--;
  },
  project_module_assembly_tasks_success(state, { tasks }) {
    state.projectAssemblyTasks = tasks;
  },
};
const actions = {
  async fetchAssemblyTasks({ commit, rootGetters }) {
    try {
      commit('make_request');
      const response = await queryTasksV2(rootGetters.project.license, [
        {
          column: 'type',
          operator: '=',
          values: ['dhme-assembly-phase'],
        },
      ]);
      commit('assembly_tasks_success', response.tasks);
    } catch (e) {
      this.commit('showNotification', {
        content: e.message,
        color: 'error',
      });
    }
  },
  async fetchAssemblyHallsAndConfigurations({ commit, rootGetters }) {
    try {
      commit('make_request');
      const { assemblyHalls, calendar } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_ASSEMBLY_HALLS',
            project: rootGetters.project.id,
            as: 'assemblyHalls',
            columns: [
              {
                name: 'hall',
                as: 'title',
              },
              {
                name: 'automated',
              },
              {
                name: 'type',
                conditions: [
                  {
                    operator: '=',
                    value: 'assembly',
                  },
                ],
              },
            ],
          },
          {
            name: 'CFFA_DHME_CALENDAR',
            project: rootGetters.project.id,
            as: 'calendar',
            columns: [
              {
                name: 'label',
              },
              {
                name: 'date',
              },
            ],
          },
        ],
      });
      const { configurations, locations } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_ASSEMBLY_LOCATIONS',
            project: rootGetters.project.id,
            as: 'locations',
            sortBy: 'order',
            columns: [
              {
                name: 'assembly_hall',
                conditions: assemblyHalls.records.map((r) => {
                  return {
                    operator: '=',
                    value: r.id,
                    boolean: 'or',
                  };
                }),
              },
              {
                name: 'assembly_lane',
              },
              {
                name: 'assembly_location',
              },
              {
                name: 'description',
              },
              {
                name: 'order',
              },
              {
                name: 'hall_configuration',
                as: 'configuration',
              },
            ],
          },
          {
            name: 'CFFA_DHME_ASSEMBLY_HALL_CONFIGURATIONS',
            project: rootGetters.project.id,
            as: 'configurations',
            columns: [
              {
                name: 'assembly_hall',
                conditions: assemblyHalls.records.map((r) => {
                  return {
                    operator: '=',
                    value: r.id,
                    boolean: 'or',
                  };
                }),
              },
              {
                name: 'title',
              },
            ],
          },
        ],
      });

      commit('assembly_halls_success', {
        calendar: calendar.records,
        halls: assemblyHalls.records,
        configurations: configurations.records,
        locations: locations.records,
      });
    } catch (e) {
      this.commit('showNotification', {
        content: e.message,
        color: 'error',
      });
    }
  },
  async fetchModulesInOrder({ commit, rootGetters, getters }) {
    try {
      commit('make_request');
      const { modules } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_MODULES',
            project: getters.focusedTask?.task_project.id,
            as: 'modules',
            sortBy: 'assembly_sequence',
            columns: [
              {
                name: 'module_id',
              },
              {
                name: 'module_type',
              },
              {
                name: 'assembly_sequence',
              },
              {
                name: 'assembly_workload',
              },
              {
                name: '2d_drawing',
              },
            ],
          },
        ],
      });

      commit('modules_success', {
        tableId: modules.id,
        modules: modules.records,
      });

      const { tasks } = await queryTasksV2(rootGetters.project.license, [
        {
          column: 'type',
          operator: '=',
          values: ['dhme-module-assembly'],
        },
        {
          column: 'project',
          operator: '=',
          values: [getters.focusedTask?.task_project?.id],
        },
      ]);
      commit('project_module_assembly_tasks_success', {
        tasks: tasks,
      });
    } catch (e) {
      this.commit('showNotification', {
        content: e.message,
        color: 'error',
      });
    }
  },

  async importAssemblyTasks({ commit, getters, dispatch, state }, { tasks }) {
    commit('make_request');
    const importedTasks = await importTasksV2(tasks);
    state.importCounter = 0;
    for (const m of getters.projectModules.filter((m) => m['2d_drawing'])) {
      let task = importedTasks.find((t) => t.sbscode?.code === m.module_id);
      let drawing = task?.appendixes.find(
        (a) => a.name === `2D_${m.module_id}`
      );
      if (drawing) {
        await removeAppendixV2(task.id, drawing.id);
      }
      let document = await downloadDocumentV2(
        getters.projectModuleTableId,
        m.id,
        m['2d_drawing']
      );
      await uploadAppendixV2(task.id, {
        data: document.file,
        extension: document.extension,
        name: `2D_${m.module_id}`,
      });
      state.importCounter++;
    }
    commit('request_success');

    commit(
      'update_assembly_tasks',
      importedTasks.filter((t) => t.task_type.type === 'dhme-assembly-phase')
    );
  },
  async rejectPlanning({ commit, getters, dispatch, state }, { tasks }) {
    commit('make_request');
    const importedTasks = await importTasksV2(tasks);
    commit('update_assembly_tasks', importedTasks);
  },
};
const getters = {
  requestedAssemblyTasks: (state) =>
    state.assemblyTasks.filter((t) => t.task_type.custom_1 === 'request'),
  plannedAssemblyTasks: (state) =>
    state.assemblyTasks.filter((t) => t.task_type.custom_1 === 'planned'),
  focusedTask: (state) => state.focusedTask,
  focusedHall: (state) => state.focusedHall,
  assemblyHalls: (state) => state.assemblyHalls,
  assemblyConfigurations: (state) => state.assemblyConfigurations,
  assemblyLocations: (state) => state.assemblyLocations,
  vacationCalendar: (state) => state.calendar,
  isLoading: (state) => state.requestCounter > 0,
  projectModules: (state) => state.projectModules,
  projectAssemblyTasks: (state) => state.projectAssemblyTasks,
  projectModuleTableId: (state) => state.projectModuleTableId,
  importCounter: (state) => state.importCounter,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
