import { getModuleData } from '@/services/api/module.api';
import { bookOfObjects } from '@/services/bookOf';
import {
  createRecord,
  importRecords,
  updateRecord,
} from '@/services/api/record.api';
import moment from 'moment';
import { getTasks } from '@/services/api/task.api';
import TaskHelper from '@/services/task-helper';
import { queryTasksV2 } from '@/services/api/v2/tasks.v2.api';

const state = {
  moduleData: {},
  moduleStatus: '',
  projectAssemblyTasks: [],
  projectAssemblyTasksStatus: '',
};
const mutations = {
  module_project_assembly_planning_request(state) {
    state.moduleStatus = 'loading';
  },
  module_project_assembly_planning_success(state, data) {
    state.moduleData = data;
    state.moduleStatus = 'success';
  },
  dhme_assembly_tasks_update(state, tasks) {
    tasks.forEach((t1) => {
      Object.assign(
        state.projectAssemblyTasks.find((t2) => t2.id === t1.id),
        t1
      );
    });
  },
  dhme_assembly_tasks_request(state) {
    state.projectAssemblyTasksStatus = 'loading';
  },
  dhme_assembly_tasks_success(state, tasks) {
    state.projectAssemblyTasks = TaskHelper.mapDefaultTreeItems(tasks);
    state.projectAssemblyTasksStatus = 'success';
  },
};
const actions = {
  fetchProjectAssemblyPlanningData(
    { commit },
    { projectId, moduleId, sessionId }
  ) {
    commit('module_project_assembly_planning_request');
    return new Promise((resolve, reject) => {
      getModuleData(projectId, moduleId, sessionId)
        .then((data) => {
          commit('module_project_assembly_planning_success', data);
          resolve(data);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject(error);
        });
    });
  },
  async fetchDhmeAssemblyPlanningTasks({ commit, getters }) {
    commit('dhme_assembly_tasks_request');
    const response = await queryTasksV2(getters.selectedLicense.id, [
      {
        column: 'project',
        operator: '=',
        values: [getters.project.id],
      },
      {
        column: 'type',
        operator: '=',
        values: ['dhme-assembly-phase', 'dhme-module-assembly'],
      },
    ]);
    const tasks = response.tasks;
    commit('dhme_assembly_tasks_success', tasks);
    if (
      tasks.filter((t) => t.task_type.type === 'dhme-assembly-phase').length ===
      0
    ) {
      commit('showNotification', {
        content:
          'No assembly phase found, please add an assembly phase in the project planning app',
        color: 'error',
      });
    }
    if (
      tasks.filter((t) => t.task_type.type === 'dhme-assembly-phase').length > 1
    ) {
      commit('showNotification', {
        content: 'Only 1 assembly phase is allowed per project',
        color: 'error',
      });
    }
  },
};
const getters = {
  dhmeProjectAssemblyPlanningStatus: (state) => state.moduleStatus,
  dhmeProjectAssemblyPlanningModules: (state) =>
    state.moduleData.CFFA_DHME_MODULES.records,
  dhmeProjectAssemblyPlanningAssemblyLocations: (state) =>
    state.moduleData.CFFA_DHME_ASSEMBLY_LOCATIONS.records,
  dhmeProjectAssemblyPlanningAssemblyHalls: (state) =>
    state.moduleData.CFFA_DHME_ASSEMBLY_HALLS.records,
  dhmeProjectAssemblyPlanningAssemblyHallConfigurations: (state) =>
    state.moduleData.CFFA_DHME_ASSEMBLY_HALL_CONFIGURATIONS.records,
  dhmeProjectAssemblyPlanningCalendar: (state) => {
    return state.moduleData.CFFA_DHME_CALENDAR.records.map((item) => {
      item.date = moment(item.date).format('YYYY-MM-DD');
      return item;
    });
  },
  dhmeProjectAssemblyHallLocations: (state, getters) => {
    return getters.dhmeProjectAssemblyPlanningAssemblyLocations
      .filter(
        (l) =>
          l.hall_configuration ===
          getters.dhmeProjectAssemblyHallConfiguration?.id
      )
      .sort((a, b) => {
        if (a.assembly_lane !== b.assembly_lane) {
          return a.assembly_lane - b.assembly_lane;
        }
        return a.assembly_location - b.assembly_location;
      });
  },
  dhmeProjectAssemblyHallTime: (state, getters) => {
    return getters.dhmeProjectAssemblyPhase?.task_type.custom_5;
  },
  dhmeProjectAssemblyHallSpeed: (state, getters) => {
    return getters.dhmeProjectAssemblyPhase?.task_type.custom_6;
  },
  dhmeProjectAssemblyHallConfiguration: (state, getters) => {
    return getters.dhmeProjectAssemblyPlanningAssemblyHallConfigurations.find(
      (config) =>
        config.id === getters.dhmeProjectAssemblyPhase?.task_type.custom_4
    );
  },
  dhmeProjectAssemblyHall: (state, getters) => {
    return getters.dhmeProjectAssemblyPlanningAssemblyHalls.find(
      (hall) => hall.id === getters.dhmeProjectAssemblyPhase?.task_type.custom_3
    );
  },
  dhmeProjectAssemblyTasks: (state) =>
    state.projectAssemblyTasks.filter(
      (task) => task.task_type.type === 'dhme-module-assembly'
    ),
  dhmeProjectAssemblyPhase: (state) =>
    state.projectAssemblyTasks.find(
      (task) => task.task_type.type === 'dhme-assembly-phase'
    ),
  dhmeProjectAssemblyTasksStatus: (state) => state.projectAssemblyTasksStatus,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
